import React, { useState, useRef, useEffect } from "react";
import "./Pricing.css";
import "./PriceSwitch.css";
import PricingPiouPiou from "./PricingPiouPiou";
import PricingKids from "./PricingKids"
import PricingYoungs from "./PricingYoungs"

function Pricing() {
  const [activeBlock, setActiveBlock] = useState(0);
  const carouselRef = useRef(null);
  const isAnimatingRef = useRef(false);

  const handleClick = (blockIndex) => {
    setActiveBlock(blockIndex);
    scrollToBlock(blockIndex);
  };

  const handleScroll = () => {
    if (isAnimatingRef.current) return;

    const carousel = carouselRef.current;
    if (carousel) {
      const currentIndex = Math.round(carousel.scrollLeft / carousel.clientWidth);
      if (currentIndex !== activeBlock) {
        setActiveBlock(currentIndex);
      }
    }
  };

  const scrollToBlock = (blockIndex) => {
    const carousel = carouselRef.current;
    if (carousel) {
      isAnimatingRef.current = true;
      carousel.scrollTo({
        left: blockIndex * carousel.clientWidth,
        behavior: "smooth",
      });

      // Réduire la durée d'animation pour s'assurer que le défilement est terminé
      setTimeout(() => {
        isAnimatingRef.current = false;
      }, 300); // Durée de l'animation en ms (ajustée pour correspondre à la durée de l'animation CSS)
    }
  };

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      carousel.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (carousel) {
        carousel.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (!isAnimatingRef.current) {
      scrollToBlock(activeBlock);
    }
  }, [activeBlock]);

  return (
    <div className="Pricing">
      <div className="PriceSwitch">
        <div className="PriceSwitch__button__container">
          <div
            className="PriceSwitch__background"
            style={{
              transform: `translateX(${activeBlock * 100}%)`,
              width: `${100 / 3}%`,
              transition: "transform 0.2s ease",
            }}
          ></div>
          {["Piou Piou", "Kids", "Youngs"].map((label, index) => (
            <button
              key={index}
              onClick={() => handleClick(index)}
              className={activeBlock === index ? "active" : ""}
            >
              <p>{label}</p>
            </button>
          ))}
        </div>
      </div>
      <div className="Pricing__carousel" ref={carouselRef}>
        <div className="Pricing__blocks">
          <div className="Pricing__block Pricing__block1">
            <PricingPiouPiou />
          </div>
          <div className="Pricing__block Pricing__block2">
          <PricingKids />
          </div>
          <div className="Pricing__block Pricing__block3">
            <PricingYoungs />
          </div>
        </div>
      </div>
      <div className="Pricing__dots">
        {["Piou Piou", "Kids", "Youngs"].map((_, index) => (
          <React.Fragment key={index}>
            <div className={`Pricing__dot ${activeBlock === index ? "Pricing__dot--active" : ""}`}></div>
            {index < 2 && <div className="Pricing__dot__space"></div>}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default Pricing;
