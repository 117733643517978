import React, { useState } from "react";
import "./PricingPiouPiou.css";
import logo from "../../img/logo.png";
import CTA from "../../component/CTA";

function PricingPiouPiou() {
  const [selectedLevelGroup, setSelectedLevelGroup] = useState("A1.1");
  const [selectedLevelIndividual, setSelectedLevelIndividual] = useState("A1.1");

  const handleGroupLevelClick = (level) => {
    setSelectedLevelGroup(level);
  };

  const handleIndividualLevelClick = (level) => {
    setSelectedLevelIndividual(level);
  };

  const groupPrices = {
    "A1.1": "8 100 ₹",
    "A1.2": "7 100 ₹",
    "A2.1": "8 200 ₹",
    "A2.2": "9 100 ₹",
  };

  const individualPrices = {
    "A1.1": "1 400 ₹",
    "A1.2": "2 400 ₹",
    "A2.1": "3 400 ₹",
    "A2.2": "1 400 ₹",
  };

  return (
    <div className="PricingPiouPiou">
      <div className="Price__offer1">
        <div className="Price__offer__container">
          <div className="Price__offer__logo">
            <img src={logo} alt="" />
          </div>
          <div className="Price__offer__coursetitle">
            <h2>French group class</h2>
          </div>
          <div className="Price__offer__courseprice">
            <p>{groupPrices[selectedLevelGroup]} / one time payment</p>
          </div>
          <div className="Price__offer__greyline"></div>
          <div className="Price__offer__bulletpoints">
            <li><p>Engaging group lessons with other kids</p></li>
            <li><p>Experienced teachers guiding the class</p></li>
            <li><p>Regular feedback and updates for parents</p></li>
          </div>
          <div className="Price__offer__levels">
            {["A1.1", "A1.2", "A2.1", "A2.2"].map((level) => (
              <div
                key={level}
                className={`Price__offer__level ${
                  selectedLevelGroup === level ? "selected" : ""
                }`}
                onClick={() => handleGroupLevelClick(level)}
              >
                <p>{level}</p>
              </div>
            ))}
          </div>
          <div className="Price__offer__cta">
            <CTA label="View available classes" bg="#FFDDD2" color="black"/>
          </div>

          <div className="Price__offer__terms">
            <p>Offer subject to conditions</p>
          </div>
        </div>
      </div>
      <div className="Price__offer2">
        <div className="Price__offer__container">
          <div className="Price__offer__logo">
            <img src={logo} alt="" />
          </div>
          <div className="Price__offer__coursetitle2">
            <h2>French individual class</h2>
          </div>
          <div className="Price__offer__courseprice">
            <p>{individualPrices[selectedLevelIndividual]} / one time payment</p>
          </div>
          <div className="Price__offer__greyline"></div>
          <div className="Price__offer__bulletpoints">
            <li><p>Personalized one-on-one tutoring</p></li>
            <li><p>Direct feedback and guidance from teacher</p></li>
            <li><p>Regular feedback and updates for parents</p></li>
          </div>
          <div className="Price__offer__levels">
            {["A1.1", "A1.2", "A2.1", "A2.2"].map((level) => (
              <div
                key={level}
                className={`Price__offer__level ${
                  selectedLevelIndividual === level ? "selected" : ""
                }`}
                onClick={() => handleIndividualLevelClick(level)}
              >
                <p>{level}</p>
              </div>
            ))}
          </div>
          <div className="Price__offer__cta">
            <CTA label="View available classes" bg="#D3D2FF" color="black"/>
          </div>

          <div className="Price__offer__terms">
            <p>Offer subject to conditions</p>
          </div>
        </div>
      </div>
      <div className="Price__offer3">
        <div className="Price__offer__container">
          <div className="Price__offer__logo">
            <img src={logo} alt="" />
          </div>
          <div className="Price__offer__coursetitle3">
            <h2>Tuitions</h2>
          </div>
          <div className="Price__offer__courseprice">
            <p>9 999 ₹ / 10 class</p>
          </div>
          <div className="Price__offer__greyline"></div>
          <div className="Price__offer__bulletpoints">
            <li><p>Comprehensive curriculum from A1 to B2</p></li>
            <li><p>Flexible scheduling for all classes</p></li>
            <li><p>Regular feedback and updates for parents</p></li>
          </div>
          <div className="Price__offer__cta2">
            <CTA label="View available classes" bg="#FFFDD2" color="black"/>
          </div>

          <div className="Price__offer__terms">
            <p>Offer subject to conditions</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PricingPiouPiou;
