import React from "react";
import "./Prosandcons.css";
import arrowlook from "../../img/arrowlook.svg";
import { motion } from "framer-motion";

function Prosandcons() {
  const fadeInUp = {
    hidden: { opacity: 1, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  };
  return (
    <div className="Prosandcons">
      <div className="Prosandcons__sentence__arrow">
        <div className="Prosandcons__sentence__arrow__txt">
          <p>There's and easier way</p>
        </div>
        <div className="Prosandcons__sentence__arrow__svg">
          <img src={arrowlook} alt="" />
        </div>
      </div>
      <div className="Prosandcons__container">
        <div className="Prosandcons__pros">
          <h3>Without Le Cercle Français</h3>
          <div className="Prosandcons__pros__what">
            <p>
              <span>Struggling</span> to organize fragmented resources
              <br />
              <br /> <br />
              <span>Expensive</span> one-on-one lessons or generic group classes
              that don't suit your child's needs
              <br /> <br /> <br />
              <span>Lessons that are monotonous</span> and solely based on books
              <br /> <br /> <br />
              <span>Rigid schedules</span> that don't fit your lifestyle
              <br /> <br /> <br />
              <span>The hassle and cost</span> of finding the right teacher
              <br /> <br /> <br />
              <span>Lack of confidence</span> in speaking and dealing with a
              heavy accent
              <br /> <br /> <br />
              <span>No guidance or support</span> to keep your child on track
              <br /> <br /> <br />
              <span>Feeling isolated</span> in your learning process
              <br /> <br /> <br />
              <span>Frustration </span> that leads to giving up on your progress
            </p>
          </div>
        </div>
        <div className="Prosandcons__sentence__arrow__mobile">
          <div className="Prosandcons__sentence__arrow__txt__mobile">
            <p>There's and easier way</p>
          </div>
          <div className="Prosandcons__sentence__arrow__svg__mobile">
            <img src={arrowlook} alt="" />
          </div>
        </div>
        <div className="Prosandcons__cons">
          <h3>With Le Cercle Français</h3>
          <div className="Prosandcons__cons__what">
            <p>
              <span>Individual and group</span> classes for a flat price
              <br /> <br /> <br />
              <span>Learn</span> practical, real-world French that your child
              will actually use
              <br /> <br /> <br />
              <span>Flexible</span> scheduling for individual classes
              <br /> <br /> <br />
              <span>Find</span> the ideal teacher for your child
              <br /> <br /> <br />
              <span>A safe, supportive</span> space to practice, make mistakes,
              and build confidence
              <br /> <br /> <br />
              <span>Continuous motivation</span> and guidance from your teachers
              <br /> <br /> <br />
              <span>Fast progress</span> and inspiring results, every step of
              the way
              <br /> <br /> <br />
              <span>Deep connections</span> with a community that supports your
              child's journey
              <br /> <br /> <br />
              <span>All-in-one platform</span> that brings together everything
              you need for success
            </p>
          </div>
        </div>
      </div>
      <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.01 }}
            variants={fadeInUp}
          >
      <div className="Prosandcons__bonjour">
        <p>Bonjour, ca va ? </p>
      </div>
      </motion.div>
    </div>
  );
}

export default Prosandcons;
