import React from "react";
import "./OurClasses.css";
import CardClass from "./CardClass";
import pioupioucourse from "../../img/pioupioucourse.png";
import youngscourse from "../../img/youngscourse.png";
import kidscourse from "../../img/kidscourse.png";

import { motion } from "framer-motion";

function OurClasses() {
  const fadeInUp = {
    hidden: { opacity: 1, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  };
  return (
    <div className="OurClasses">
      <div className="OurClasses__h2">
        <h2>Our French classes</h2>
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.01 }}
            variants={fadeInUp}
          >
        <div className="OurClasses__CardClass">
         
            <CardClass
              img={pioupioucourse}
              title="Piou Piou"
              subtitle="2 - 6 years old"
              badge="19 sit available"
            />
 

          <div className="OurClasses__18px__horizontal__space"></div>
          <CardClass
            img={kidscourse}
            title="Kids"
            subtitle="7 - 12 years old"
            badge="12 sit available"
          />
          <div className="OurClasses__18px__horizontal__space"></div>
          <CardClass
            img={youngscourse}
            title="Youngs"
            subtitle="13 - 17 years old"
            badge="22 sit available"
          />
        </div>
        </motion.div>
        <div className="OurClasses__viewmore">
          
          <p>View more</p>
        </div>
      </div>
    </div>
  );
}

export default OurClasses;
