import React from "react";
import "./ValueProposition.css";
import arrowlook from "../../img/arrowlook.svg";
import CTA from "../../component/CTA";
import { AutoAwesome, CheckCircle, Star } from "@mui/icons-material";
import leftwing from "../../img/leftwing.svg";
import rightwing from "../../img/rightwing.svg";

function ValueProposition() {
  return (
    <div className="ValueProposition">
      <div className="ValueProposition__container">
        <div className="ValueProposition__title">
          <div className="ValueProposition__title__h1">
            <h1>
              French Made Fun, <span>Just for Kids</span>
            </h1>
          </div>

          <div className="ValueProposition__rectangle"></div>
        </div>
      </div>
      <div className="ValueProposition__subtitle">
        <div className="ValueProposition__subtitle__txt">
          <p>
            Tired of boring language learning Apps ? <br />
            Experience engaging French classes online - Learning made fun for
            your child!
          </p>
        </div>
        <div className="ValueProposition__arrow">
          <img src={arrowlook} alt="" />
        </div>
      </div>
      <div className="ValueProposition__CTA__zone">
        <div className="ValueProposition__CTA__input">
          <input
            type="text"
            className="ValueProposition_Mail_input"
            placeholder="Enter your email address"
          />
        </div>
        <div className="ValueProposition__CTA">
          <CTA label="Claim your free class" />
        </div>
      </div>
      <div className="ValutProposition__text__icon">
        <div className="ValutProposition__text__icon__ai">
          <AutoAwesome />
        </div>

        <p>Get your private class, 100% free</p>
      </div>
      <div className="ValueProposition__award">
        <div className="ValueProposition__award__wing1">
          <img src={leftwing} alt="" />
        </div>
        <div className="ValueProposition__award__middleblock">
          <div className="ValueProposition__award__middleblock__txt">
            <p>286 happy students</p>
          </div>
          <div className="ValueProposition__award__stars">
            <Star />
            <Star />
            <Star />
            <Star />
            <Star />
          </div>
        </div>
        <div className="ValueProposition__award__wing2">
          <img src={rightwing} alt="" />
        </div>
      </div>
      <div className="ValueProposition__3__values">
        <div className="ValueProposition__check__text">
          <div className="ValueProposition__check">
            <CheckCircle />
          </div>
          <div className="ValueProposition__text">
            <p>From A1 to B2 level</p>
          </div>
        </div>
        <div className="ValueProposition__check__text">
          <div className="ValueProposition__check">
            <CheckCircle />
          </div>
          <div className="ValueProposition__text">
            <p>Experienced teachers</p>
          </div>
        </div>
        <div className="ValueProposition__check__text">
          <div className="ValueProposition__check">
            <CheckCircle />
          </div>
          <div className="ValueProposition__text">
            <p>Online Platform</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ValueProposition;
