import React, { useState } from "react";
import "./FAQ.css";
import { Add, Remove } from "@mui/icons-material";

function FAQ() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpansion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: "What is Le Cercle Français?",
      answer: "Le texte s'affiche ici.",
    },
    {
        question: "What material is needed for the classes?",
        answer: "Le texte s'affiche ici.",
      },
      {
        question: "Learning and speaking French?",
        answer: "Le texte s'affiche ici.",
      },
      {
        question: "Why taking French class?",
        answer: "Le texte s'affiche ici.",
      },
      {
        question: "What is happening in a class?",
        answer: "Le texte s'affiche ici.",
      },
      {
        question: "What about the exams?",
        answer: "Le texte s'affiche ici.",
      },
      {
        question: "Will my kid get a Certification?",
        answer: "Le texte s'affiche ici.",
      },
      {
        question: "How much time to become fluent?",
        answer: "Le texte s'affiche ici.",
      },
      {
        question: "I am a beginner, where do I start?",
        answer: "Le texte s'affiche ici.",
      },
  ];

  return (
    <div className="Margin__global">
      <div className="FAQ">
        <div className="FAQ__leftblock">
          <h2>Frequently Asked Questions</h2>
          <p>
            Have another question? Contact us by email at{" "}
            <span><b>contact@lecerclefrancais.in</b></span>
          </p>
        </div>
        <div className="FAQ__rightblock">
        <div className="FAQ__greyline"></div>
          {faqItems.map((item, index) => (
            <div key={index}>
              <div className="FAQ__expandable" onClick={() => toggleExpansion(index)}>
                <div className="FAQ__expandable__label">
                  <p><b>{item.question}</b></p>
                </div>
                <div className="FAQ__expandable__add">
                  {expandedIndex === index ? <Remove /> : <Add />}
                </div>
              </div>
              <div className={`FAQ__answer ${expandedIndex === index ? 'visible' : ''}`}>
                <p>{item.answer}</p>
              </div>
              <div className="FAQ__greyline"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAQ;
